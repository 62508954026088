import { GAME_PAGE_URL } from '~/constants/lobby'
import {
  IMenuHeader,
  IMenuUserDesktop,
  IMenuWithDraw,
  IMenuDepositBank,
  IMenuDepositDesktop,
  IMenuDeposit,
  IMenuMobile,
  IMenuBottom
} from '~/types/menu.type'
import { ACCOUNT_URLS, LOBBY_CASINO_URLS, LOBBY_GAME_URLS, PAGE_URLS } from '~/config/page-url'
import { LABEL } from '~/constants/account/history/transactions'

export const PATH_ICON_MENU_BOTTOM = '/assets/images/components/desktop/menu/menu-bottom/'

export const PATH_ICON_ACCOUNT = '/assets/images/components/desktop/menu/account/'

export const LOGO_SITE_IMAGE = '/assets/images/logo.svg'

export const IMAGE_PATH_ACCOUNT = '/assets/images/components/desktop/pages/account/layout/'
export const IMAGE_PATH_DEPOSIT = '/assets/images/components/desktop/pages/account/deposit/'

export const MENUS: IMenuHeader[] = [
  {
    type: 'sports',
    name: 'sports',
    tab: 'sports',
    title: 'Thể thao',
    activeUrl: '/the-thao'
  },
  {
    type: 'livecasino',
    name: 'casino',
    title: 'Live Casino',
    tab: 'tab-casino',
    activeUrl: `${LOBBY_CASINO_URLS.ALL}`,
    subMenu: []
  },
  {
    type: 'games',
    name: 'game',
    title: 'Cổng Games',
    tab: 'tab-games',
    activeUrl: LOBBY_GAME_URLS.ALL,
    subMenu: []
  },
  {
    type: 'game-bai',
    name: 'game-bai',
    title: 'Game Bài',
    tab: 'game-bai',
    activeUrl: LOBBY_GAME_URLS.GAME_BAI,
    subMenu: []
  },
  {
    type: 'lo-de',
    name: 'lo-de',
    title: 'Lô đề',
    tab: 'lo-de',
    activeUrl: LOBBY_GAME_URLS.LO_DE,
    subMenu: []
  }
]

const PATH_ICON_COMMON = '/assets/images/components/common/'
export const PATH_ICON = '/assets/images/components/common/'

export const USER_MENU: IMenuUserDesktop[] = [
  {
    id: 1,
    name: 'Quản lí tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: PATH_ICON_COMMON + 'account-icon.svg',
    activeUrl: ACCOUNT_URLS.PROFILE,
    activeUrlRelative: ACCOUNT_URLS.BANK,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-profile'
  },
  {
    id: 2,
    name: 'Nạp tiền',
    url: ACCOUNT_URLS.DEPOSIT_DA,
    icon: PATH_ICON_COMMON + 'deposit-icon.svg',
    activeUrl: ACCOUNT_URLS.DEPOSIT_DA,
    activeUrlRelative: ACCOUNT_URLS.DEPOSIT,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-deposit'
  },
  {
    id: 3,
    name: 'Rút tiền',
    url: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: PATH_ICON_COMMON + 'withdraw-icon.svg',
    activeUrl: ACCOUNT_URLS.WITHDRAW_BANK,
    activeUrlRelative: ACCOUNT_URLS.WITHDRAW,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-withdraw'
  },
  {
    id: 4,
    name: 'Giao dịch P2P',
    url: ACCOUNT_URLS.P2P,
    icon: PATH_ICON_ACCOUNT + 'p2p.svg',
    activeUrl: ACCOUNT_URLS.P2P,
    activeUrlRelative: ACCOUNT_URLS.P2P,
    hiddenInTab: false,
    isLogout: false,
    img: 'p2p'
  },
  {
    id: 5,
    name: 'Lịch sử',
    url: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: PATH_ICON_COMMON + 'history-icon.svg',
    activeUrl: ACCOUNT_URLS.TRANSACTION_HISTORY,
    activeUrlRelative: ACCOUNT_URLS.BET_HISTORY,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-history'
  },
  {
    id: 7,
    name: 'Đăng xuất',
    url: '',
    icon: PATH_ICON_COMMON + 'logout-icon.svg',
    activeUrl: '',
    activeUrlRelative: '',
    hiddenInTab: false,
    isLogout: true
  }
]

export const MENU_WITHDRAW: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-bank',
    iconMB: 'icon-bank',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_BANK
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.WITHDRAW_CRYPTO,
    icon: 'icon-crypto',
    iconMB: 'icon-crypto',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CRYPTO
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.WITHDRAW_CARD,
    icon: 'icon-card',
    iconMB: 'icon-card',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CARD
  }
]

export const MENU_DEPOSIT: IMenuDeposit[] = [
  {
    name: 'CodePay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    key: 'nicepayBanks',
    desc: 'Khuyến mãi hấp dẫn',
    icon: 'icon-codepay',
    isHot: true,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-codepay2',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền ảo',
    desc: 'Tặng 0.8%',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    icon: 'icon-crypto',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: true,
    isMaintain: false
  },
  {
    name: 'Ví điện tử',
    queryValue: 'momo',
    fullPath: ACCOUNT_URLS.DEPOSIT_MOMO,
    key: 'momos',
    desc: 'Momo, Viettel Money',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ cào',
    desc: 'Nhiều nhà mạng hỗ trợ',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.DEPOSIT_CARD,
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]

export const MENU_DEPOSIT_DESKTOP: IMenuDepositDesktop[] = [
  {
    name: 'Ngân hàng',
    queryValue: 'codepay',
    fullPath: [ACCOUNT_URLS.DEPOSIT_BANK, ACCOUNT_URLS.DEPOSIT_DA, ACCOUNT_URLS.DEPOSIT_CODEPAY2],
    key: 'nicepayBanks',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-bank',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    component2: null,
    componentMobile: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền ảo',
    desc: 'Tặng 0.8%',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CRYPTO],
    icon: 'icon-crypto',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/crypto/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Ví điện tử',
    queryValue: 'momo',
    fullPath: [ACCOUNT_URLS.DEPOSIT_MOMO],
    key: 'momos',
    desc: 'Siêu tiện lợi, nhanh chóng',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/e-wallet/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ cào',
    desc: 'Mệnh giá đa dạng',
    queryValue: 'card',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CARD],
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/card/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]

export const MENU_DEPOSIT_BANK: IMenuDepositBank[] = [
  {
    name: 'CodePay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    pathActive: ACCOUNT_URLS.DEPOSIT_BANK,
    key: 'nicepayBanks',
    icon: 'icon-codepay',
    iconActive: IMAGE_PATH_DEPOSIT + 'icon-codepay-active.svg',
    isHot: true,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    icon: 'icon-codepay2',
    iconActive: IMAGE_PATH_DEPOSIT + 'icon-gopay-active.svg',
    isHot: false,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  }
]

export const MENU_HISTORY = [
  {
    name: LABEL.HISTORY_TRANSACTIONS,
    link: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: 'icon-history-transaction',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/transactions/index.vue')),
    componentMobile: defineAsyncComponent(
      () => import('~/components/mobile/pages/account/history/transactions/index.vue')
    )
  },
  {
    name: LABEL.HISTORY_BETS,
    link: ACCOUNT_URLS.BET_HISTORY,
    icon: 'icon-history-bets',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/bets/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/history/bet/index.vue'))
  }
]

export const NOTE = [
  'Nạp / Rút bằng tài khoản chính chủ.',
  'Hỗ trợ chuyển tiền liên ngân hàng.',
  'Lưu lại biên lai để đối chiếu khi cần.'
]

export const USER_MENU_MOBILE: IMenuMobile[] = [
  {
    name: 'Quản lý tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: 'icon-profile',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Quản lý ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: 'icon-bank',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Lịch sử giao dịch',
    url: `${ACCOUNT_URLS.TRANSACTION_HISTORY}`,
    icon: 'icon-history-transaction',
    activeUrl: [ACCOUNT_URLS.TRANSACTION_HISTORY],
    isLiveChat: false
  },
  {
    name: 'Lịch sử cá cược',
    url: `${ACCOUNT_URLS.BET_HISTORY}`,
    icon: 'icon-history-bets',
    activeUrl: [ACCOUNT_URLS.BET_HISTORY],
    isLiveChat: false
  },
  {
    name: 'Live chat 24/7',
    url: '',
    icon: 'icon-livechat',
    activeUrl: [],
    isLiveChat: true
  }
]

export const BOTTOM_MENU: IMenuBottom[] = [
  {
    name: 'Khuyến Mãi',
    url: PAGE_URLS.PROMOTION,
    activeUrl: PAGE_URLS.PROMOTION,
    icon: PATH_ICON_MENU_BOTTOM + 'promotion-default.svg',
    iconActive: PATH_ICON_MENU_BOTTOM + 'promotion-default.svg'
  },
  {
    name: 'Trang Chủ',
    url: PAGE_URLS.HOME,
    activeUrl: PAGE_URLS.HOME,
    icon: PATH_ICON_MENU_BOTTOM + 'home-default.svg',
    iconActive: PATH_ICON_MENU_BOTTOM + 'home-active.svg'
  },
  {
    name: 'Thể Thao',
    url: PAGE_URLS.SPORT,
    activeUrl: PAGE_URLS.SPORT,
    icon: PATH_ICON_MENU_BOTTOM + 'sports-default.svg',
    iconActive: PATH_ICON_MENU_BOTTOM + 'sports-active.svg'
  },
  {
    name: 'Cổng Game',
    url: LOBBY_GAME_URLS.TABLE_GAME,
    activeUrl: LOBBY_GAME_URLS.TABLE_GAME,
    icon: PATH_ICON_MENU_BOTTOM + 'game-default.svg',
    iconActive: PATH_ICON_MENU_BOTTOM + 'game-active.svg'
  },
  {
    name: 'Quay số',
    url: LOBBY_GAME_URLS.QUAY_SO,
    activeUrl: LOBBY_GAME_URLS.QUAY_SO,
    icon: PATH_ICON_MENU_BOTTOM + 'lotto-default.svg',
    iconActive: PATH_ICON_MENU_BOTTOM + 'lotto-active.svg'
  },
  {
    name: 'Live Casino',
    url: GAME_PAGE_URL.blackjack,
    activeUrl: GAME_PAGE_URL.blackjack,
    icon: PATH_ICON_MENU_BOTTOM + 'casino-default.svg',
    iconActive: PATH_ICON_MENU_BOTTOM + 'casino-active.svg'
  }
]
